<template>
    <onboarding-layout
        :loading="true"
        :error-text="errorText"
    />
</template>

<script>
    import authMixin from '../../mixins/authMixin'
    import { ApiErrorHandler } from '../../utils/exception-handler'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { getApplicantReturningHMDA } from '@/services/api'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    import { Flows, getNextRoute } from '../../flow/flowController'
    import OnboardingLayout from '@/layouts/Onboarding'

    export default {
        name: 'HMDAOnlyFlow',
        components: { OnboardingLayout },
        props: {
            returnToken: {
                type: String,
                required: true,
            },
        },
        mixins: [authMixin],
        data() {
            return {
                loading: false, // needed for assert in authMixin
                errorText: '',
            }
        },
        mounted: async function () {
            this.$logEvent('view_origination_return', {
                returnToken: this.returnToken,
            })
            try {
                if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                    logger.info(`User hit origination return to anything page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    return reloadPageWithCleanState()
                }

                const applicantReturningResponse = await getApplicantReturningHMDA(this.returnToken)
                logger.info(`Received get applicant returning response upon hmda flow: ${JSON.stringify(applicantReturningResponse.data)}`)

                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.hmda)

                return await this.$router.replace(getNextRoute(this.$router))
            } catch (error) {
                this.errorText = ApiErrorHandler(error)
            }
        },
    }
</script>

<style scoped></style>
